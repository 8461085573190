import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

function AsSeenOn() {
	const data = useStaticQuery(graphql`
		query {
			as_seen_on: file(relativePath: { eq: "as_seen_on.png" }) {
				childImageSharp {
					fluid(maxWidth: 1920, maxHeight: 120, quality: 85) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`);

	return (
		<div className=''>
			<p className='mb-3 text-center text-base leading-6 text-black font-semibold tracking-wide uppercase'>
				As Seen On
			</p>
			
			<Img
				fluid={data.as_seen_on.childImageSharp.fluid}
				alt='As seen on logos'
				className='mb-10 container left-0 right-0 w-full h-full object-cover z-10'
			/>
		</div>
	);
}

export default AsSeenOn;
