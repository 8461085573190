import { graphql } from "gatsby"
import React from "react"
import Hero from "../components/Hero"
import AsSeenOn from "../components/AsSeenOn"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import Category from "../components/Category"
import Featured from "../components/Featured"

const IndexPage = ({ data }) => {

  return (
    <Layout>
      <SiteMetadata title="Home" description="The Pixel Prince Free Wall Art" />
      <Hero />
      <AsSeenOn></AsSeenOn>
      <Featured></Featured>
      <Category></Category>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    portfolio: allContentfulPortfolio {
      nodes {
        ...PortfolioCard
      }
    }
  }
`
