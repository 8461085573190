import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

function Featured() {
	const data = useStaticQuery(graphql`
		query {
			allFeaturedEtsyListing(
				filter: { is_digital: { eq: true }, views: { gt: 1000 } }
				limit: 4
				sort: { fields: views }
			) {
				totalCount
				nodes {
					id
					description
					price
					title
					url
					childrenEtsyListingImage {
						childFile {
							childImageSharp {
								fluid(maxWidth: 2000, maxHeight: 2000, quality: 85) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
			}
		}
	`);

	return (
		<div>
			<div className='container px-4 mx-auto mt-20'>
				<div className='mb-8 lg:text-center'>
					<h3 className='py-2 mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl sm:leading-10'>
						Featured Items
					</h3>

					<div className='flex justify-start w-64 h-1 py-0 my-0 border-b-8 border-yellow-500 rounded-t opacity-25 lg:mx-auto gradient '></div>
					<p className='max-w-2xl mt-4 text-xl leading-7 text-gray-500 lg:mx-auto'>
						Popular High Resolution Printable Wall Art for Kids Room & Man Caves
					</p>
				</div>

				<div className='flex flex-wrap items-center'>
					{data.allFeaturedEtsyListing.nodes.slice(0, 4).map((item) => (
						<div
							className='w-full px-4 mb-6 md:w-6/12 lg:w-3/12 group '
							key={item.id}>
							<Link to={`/${item.id}`}>
								<Img
									fluid={
										item.childrenEtsyListingImage[1].childFile.childImageSharp
											.fluid
									}
									alt='Video game poster'
									className='transition duration-150 ease-in-out rounded-lg group-hover:opacity-75 '
								/>

								<div className='text-sm text-center'>
									<p class='text-black leading-none'>
										{item.title.split(",")[0]}
									</p>
									<p class='text-grey-dark mt-2'>${item.price}</p>
								</div>
							</Link>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default Featured;
