import { Link } from "gatsby";
import React from "react";
import BG from "../images/mainBG.jpeg";

const Hero = ({ data }) => (
	<div>
		<main>
			<div
				className='relative flex items-center content-center justify-center pt-16 pb-32'
				style={{
					minHeight: "75vh",
				}}>
				<div
					className='absolute top-0 w-full h-full bg-center bg-cover'
					style={{
						backgroundImage: `url('${BG}')`,
					}}>
					<span
						id='blackOverlay'
						className='absolute w-full h-full bg-black opacity-25'></span>
				</div>
				<div className='container relative mx-auto'>
					<div className='flex flex-wrap items-center sm:px-24 '>
						<div className='w-full px-4 ml-auto mr-auto text-center lg:w-9/12'>
							<div className='pr-12'>
								<h2 className='text-4xl font-extrabold leading-10 tracking-tight text-white sm:text-4xl sm:leading-none md:text-6xl'>
									The Pixel Prince
								</h2>
								<h2 className='py-1 text-4xl font-extrabold leading-10 tracking-tight text-yellow-500 sm:text-4xl sm:leading-none md:text-4xl lg:text-5xl '>
									Digital Downloads
								</h2>

								<p className='mt-4 mb-4 text-base text-gray-300  md:text-xl'>
									Printable Video Game Decor, Man Cave Ideas & World Maps Wall
									Decor
								</p>
								<Link to='/prints'>
									<button
										className={
											"bg-yellow-500 text-black  active:bg-gray-100" +
											" text-md font-bold uppercase px-4 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
										}
										type='button'
										style={{ transition: "all .15s ease" }}>
										<i className='fas fa-arrow-alt-circle-down'></i>
										Shop Now
									</button>
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div
					className='absolute bottom-0 left-0 right-0 top-auto w-full overflow-hidden pointer-events-none'
					style={{ height: "70px", transform: "translateZ(0)" }}>
					<svg
						className='absolute bottom-0 overflow-hidden'
						xmlns='http://www.w3.org/2000/svg'
						preserveAspectRatio='none'
						version='1.1'
						viewBox='0 0 2560 100'
						x='0'
						y='0'>
						<polygon
							className='text-gray-300 fill-current'
							points='2560 0 2560 100 0 100'></polygon>
					</svg>
				</div>
			</div>

			<section className='relative py-6'>
				<div
					className='absolute top-0 left-0 right-0 bottom-auto w-full -mt-20 overflow-hidden pointer-events-none'
					style={{ height: "80px", transform: "translateZ(0)" }}>
					<svg
						className='absolute bottom-0 overflow-hidden'
						xmlns='http://www.w3.org/2000/svg'
						preserveAspectRatio='none'
						version='1.1'
						viewBox='0 0 2560 100'
						x='0'
						y='0'>
						<polygon
							className='text-white fill-current'
							points='2560 0 2560 100 0 100'></polygon>
					</svg>
				</div>
			</section>
		</main>
	</div>
);

export default Hero;

export const query = graphql`
	query {
		background: file(relativePath: { eq: "author.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 600, maxHeight: 480, quality: 85) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`;
