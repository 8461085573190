import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

function Category() {
	const data = useStaticQuery(graphql`
		query {
			videogame: file(relativePath: { eq: "videogame.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1000, maxHeight: 1000, quality: 85) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			maps: file(relativePath: { eq: "maps.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1000, maxHeight: 1000, quality: 85) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			meme: file(relativePath: { eq: "meme.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1000, maxHeight: 1000, quality: 85) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			quote: file(relativePath: { eq: "quotes.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1000, maxHeight: 1000, quality: 85) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`);

	return (
		<div>
			<section className='pt-20 pb-20'>
				<div className='container mx-auto px-4'>
					<div className='lg:text-center mb-8'>
						<h3 className='mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900   py-2 sm:text-4xl sm:leading-10'>
							Shop by Category (Coming soon)
						</h3>
						<div className='border-b-8 flex justify-start border-yellow-500  h-1 lg:mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t '></div>

						<p className='mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto'>
							Printable Art, Digital Download, Instant Download
						</p>
					</div>
					<div className='flex flex-wrap'>
						<div className='w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12'>
							<div className='flex flex-col'>
								<Img
									fluid={data.videogame.childImageSharp.fluid}
									alt='Video game poster'
									className=''
								/>
								<div className='text-center '>
									<h5 className='text-xl font-bold'>Video Game</h5>
								</div>
							</div>
						</div>
						<div className='w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12'>
							<div className='flex flex-col'>
								<Img
									fluid={data.maps.childImageSharp.fluid}
									alt='Map of NYC poster'
									className=''
								/>
								<div className='text-center '>
									<h5 className='text-xl font-bold'>Maps + Navigation</h5>
								</div>
							</div>
						</div>
						<div className='w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12'>
							<div className='flex flex-col'>
								<Img
									fluid={data.meme.childImageSharp.fluid}
									alt='Meme poster'
									className=''
								/>
								<div className='text-center '>
									<h5 className='text-xl font-bold'>Funny + Meme</h5>
								</div>
							</div>
						</div>
						<div className='w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 '>
							<div className='flex flex-col'>
								<Img
									fluid={data.quote.childImageSharp.fluid}
									alt='Motivational poster'
									className=''
								/>
								<div className='text-center '>
									<h5 className='text-xl font-bold'>Motivational + Quotes</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Category;
